import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: auto;
  max-width: 1200px;
`;

const PageContainer = (props: { children: React.ReactNode }) => {
  return (
    <Container>
      <Wrapper>{props.children}</Wrapper>
    </Container>
  );
};
export default PageContainer;
