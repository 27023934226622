import styled from "styled-components";
import { Col, Row, Text } from "./styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 40px;
  column-gap: 50px;
  padding: 20px;
  margin-top: 20px;
  justify-content: flex-start;
  flex: 1;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 80vw;
  }
`;

const PriceCol = styled(Col)``;

const PriceRow = (props: { text: string; price?: number | string }) => {
  return (
    <Row justifyContent="space-between">
      <Row width="100%">
        <Text>{props.text}</Text>
      </Row>
      <Row width="100px" justifyContent="flex-end">
        <Text>{props.price ? props.price + " kr" : ""}</Text>
      </Row>
    </Row>
  );
};

const Prices = () => {
  return (
    <Wrapper>
      <Container>
        <PriceCol gap={20}>
          <Row>
            <Text size={20} weight={600}>
              Klippning
            </Text>
          </Row>
          <Col gap={10}>
            <PriceRow text="Studentrabatt/pensionär 10%" />
            <PriceRow text="Klippning" price={500} />
            <PriceRow text="Barn upp till 10 år" price={430} />
            <PriceRow text="Lugg" price={150} />
            <PriceRow text="Tvätt fön/läggning" price={370} />
          </Col>
        </PriceCol>
        <PriceCol gap={20}>
          <Row>
            <Text size={20} weight={600}>
              Färgning
            </Text>
          </Row>
          <Col gap={10}>
            <PriceRow text="Färgning" price={"fr. 900"} />
            <PriceRow text="Färgning och klippning" price={"fr. 1270"} />
            <PriceRow text="Slingor med hätta" price={"fr. 900"} />
            <PriceRow text="Folie slingor" price={"fr. 1050"} />
            <PriceRow text="Folie slingor 2 färger" price={"fr. 1295"} />
            <PriceRow
              text="Olaplex i färg och blekning"
              price={"fr. 300 (extra)"}
            />
            <PriceRow text="Nyansering" price={300} />
            <PriceRow text="Nyansering" price={300} />
            <PriceRow text="Tillägg på dessa färger beroende på längd och tjocklek." />
          </Col>
        </PriceCol>
      </Container>
      <Container>
        <PriceCol gap={20}>
          <Row>
            <Text size={20} weight={600}>
              Permanent
            </Text>
          </Row>
          <Col gap={10}>
            <PriceRow text="Perm-klipp" price="fr. 1050" />
            <PriceRow text="Spiralperm." price="fr. 2000" />
          </Col>
        </PriceCol>
        <PriceCol gap={20}>
          <Row>
            <Text size={20} weight={600}>
              Övrigt
            </Text>
          </Row>
          <Col gap={10}>
            <PriceRow text="Formning av bryn" price={150} />
            <PriceRow text="Brynfärg" price={150} />
            <PriceRow text="Formning + färg" price={180} />
            <PriceRow text="Fransfärg" price={300} />
            <PriceRow text="Frans + bryn" price={330} />
            <PriceRow text="Uppsättning per timme" price={700} />
            <PriceRow text="Keratinbehandling" price={"fr. 1500"} />
            <PriceRow text="Brudklädsel, prissättning efter konsultation" />
          </Col>
        </PriceCol>
      </Container>
    </Wrapper>
  );
};
export default Prices;
