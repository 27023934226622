import styled from "styled-components";
import { Col, Row, Text } from "./styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 400px;

  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  justify-content: center;
  align-items: flex-start;

  width: 50%;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
`;

const TopImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const Logo = styled.img`
  //object-fit: contain;
  width: 200px;
`;

const Title = styled.h1`
  font-size: 100px;
  color: #000;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
`;

const Top = () => {
  return (
    <Wrapper>
      <Left>
        <Col gap={20}>
          <Row>
            <Logo src="/logo.png" alt="logo" />
          </Row>
          <Col>
            <Row>
              <Text>Sjätte villagatan 7, 504 54 Borås</Text>
            </Row>
            <Row>
              <Text>
                Telefon: <a href="tel: 033-134488">033-134488</a>
              </Text>
            </Row>
          </Col>
          <Row>
            <Text size={20} weight={600}>
              Öppetider:
            </Text>
          </Row>
          <Col>
            <Row>Mån: 09:00-18:00</Row>
            <Row>Tis: Stängt</Row>
            <Row>Ons: Stängt</Row>
            <Row>Tor: 09:00-18:00</Row>
            <Row>Fre: 09:00-18:00</Row>
            <Row>Lör: Stängt</Row>
            <Row>Sön: Stängt</Row>
          </Col>
          <Row>
            <div id="fbdiv">
              <iframe
                title="fb"
                src="//www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fpages%2FTrend-h%25C3%25A5rstudio%2F351782514856158&amp;send=false&amp;layout=standard&amp;width=300&amp;show_faces=false&amp;action=like&amp;colorscheme=dark&amp;font=segoe+ui&amp;height=35&amp;"
                style={{
                  border: "none",
                  overflow: "hidden",
                  width: "300px",
                  height: "35px",
                }}
                allowTransparency={true}
              ></iframe>
            </div>
          </Row>
        </Col>
      </Left>
      <Right>
        <TopImage src="/trendbg.jpg" alt="trend" />
      </Right>
    </Wrapper>
  );
};
export default Top;
