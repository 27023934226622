import "./App.css";

import PageContainer from "./components/PageContainer";
import Prices from "./components/Prices";
import Top from "./components/Top";

function App() {
  return (
    <PageContainer>
      <Top />
      <Prices />
    </PageContainer>
  );
}

export default App;
