import styled from "styled-components";

export const Row = styled.div<{ justifyContent?: string; width?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent ?? "flex-start"};
  width: ${(props) => props.width ?? "100%"};
`;
export const Col = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.gap ?? 0}px;
`;

export const Text = styled.p<{ size?: number; weight?: number }>`
  font-size: ${(props) => props.size ?? 15}px;
  color: #000;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: ${(props) => props.weight ?? 400};
`;
